import React, { useState } from 'react';
import Script from 'next/script';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import GoogleTagManagerHead from './GoogleTagManagerHead';
import { AlternateLangs } from '../models/app';

const ENV = process.env.ENV || process.env.NEXT_PUBLIC_ENV;

const MyHead = (props: {
  title?: string;
  keywords?: string;
  description?: string;
  author?: string;
  robots?: string;
  ogType?: string;
  ogUrl?: string;
  ogImage?: string;
  ogImageWidth?: number;
  ogImageHeight?: number;
  alternates?: AlternateLangs[];
  children?: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const { asPath } = useRouter();

  const [acceptedStatistics] = useState(false);
  const [declineStatistics] = useState(false);

  const siteUrl = process.env.SITE_URL || process.env.NEXT_PUBLIC_SITE_URL;
  const canonicalUrl = `${siteUrl}${asPath}`;

  const {
    title = t('SITE_TITLE'),
    keywords = '',
    description = t('SITE_DESCRIPTION'),
    author = t('SITE_AUTHOR'),
    ogType = 'website',
    ogUrl = canonicalUrl,
    ogImage = `${siteUrl}/images/logo-meta.jpg`,
    ogImageWidth,
    ogImageHeight,
    alternates = [],
    children
  } = props;

  return (
    <div>
      <Head>
        {title && <title>{title}</title>}
        {title && <meta name="application-name" content={title} />}
        {keywords && <meta name="keywords" content={keywords} />}
        {description && <meta name="description" content={description} />}
        {author && <meta name="author" content={author} />}
        {ENV !== 'production' ? (
          <meta name="robots" content="noindex, nofollow" />
        ) : (
          <meta name="robots" content="all" />
        )}

        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

        {/* HrefLang */}
        {alternates &&
          alternates.length > 0 &&
          alternates.map(({ alternateLang, url }, key) => {
            return (
              <link
                key={key}
                rel="alternate"
                href={`${siteUrl}${url}`}
                hrefLang={alternateLang}
              />
            );
          })}

        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/favicon/apple-touch-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/favicon/apple-touch-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/favicon/apple-touch-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/favicon/apple-touch-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/favicon/apple-touch-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/favicon/apple-touch-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/favicon/apple-touch-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/favicon/apple-touch-icon-152x152.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="196x196"
          href="/favicon/favicon-196x196.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <link rel="manifest" href="/favicon/manifest.json" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta
          name="msapplication-TileImage"
          content="/favicon/ms-icon-144x144.png"
        />
        <link
          rel="shortcut icon"
          href="/favicon/favicon.ico"
          type="image/x-icon"
        />
        <meta
          name="facebook-domain-verification"
          content="7iv62tobs4o2rtvi170emkjyynfp57"
        />
        <meta
          name="google-site-verification"
          content="rXPqJTaANwhLd8vbeo2NhMJC8Aw1t1r9bEROWf-76EU"
        />
        {process.env.GOOGLE_SITE_VERIFICATION && (
          <meta
            name="google-site-verification"
            content={process.env.GOOGLE_SITE_VERIFICATION}
          />
        )}

        {/* --- Open Graph --- */}
        {title && <meta property="og:title" content={title} />}
        {description && (
          <meta property="og:description" content={description} />
        )}
        {ogType && <meta property="og:type" content={ogType} />}
        {ogImage && <meta property="og:image" content={ogImage} />}
        {ogImageWidth && (
          <meta property="og:image:width" content={ogImageWidth.toString()} />
        )}
        {ogImageHeight && (
          <meta property="og:image:height" content={ogImageHeight.toString()} />
        )}
        {ogUrl && <meta property="og:url" content={ogUrl} />}

        {/* --- Twitter --- */}
        {ogImage && <meta name="twitter:image" content={ogImage} />}
        {title && <meta name="twitter:title" content={title} />}
        {description && (
          <meta name="twitter:description" content={description} />
        )}

        {acceptedStatistics && (
          <script
            type="text/javascript"
            async
            defer
            src="/js/scripts.js"
          ></script>
        )}

        {declineStatistics && (
          <script
            type="text/javascript"
            async
            defer
            src="/js/anonymized_scripts.js"
          ></script>
        )}

        {children}

        {/* --- Fonts --- */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />

        <GoogleTagManagerHead />
      </Head>
    </div>
  );
};

MyHead.propTypes = {
  title: PropTypes.string,
  keywords: PropTypes.string,
  description: PropTypes.string,
  author: PropTypes.string,
  robots: PropTypes.string,
  ogType: PropTypes.string,
  ogImage: PropTypes.string,
  ogUrl: PropTypes.string
};

export default MyHead;
