import React from 'react';
import LinkTo from './LinkTo';

const PrimaryButtonSmall = ({ text, href }: { text: any; href: string }) => {
  return (
    <LinkTo
      href={href}
      className="bg-primary text-white px-4 py-2 rounded-md uppercase font-medium w-max flex justify-center items-center"
    >
      {text}
    </LinkTo>
  );
};

PrimaryButtonSmall.defaultProps = {
  text: '',
  href: '#'
};

export default PrimaryButtonSmall;
