/* eslint-disable unicorn/numeric-separators-style */
import React, { useEffect, useState } from 'react';
import Menu from '../../components/Menu/Menu';
import MyHead from '../../components/MyHead';
import Footer from '../../components/Footer';
import MycarouselProducts from '../../components/Carousels/MycarouselProducts';
import MycarouselHeader from '../../components/Carousels/MycarouselHeader';
import MycarouselCategories from '../../components/Carousels/MycarouselCategories';
import { Reveal, RevealProps } from 'react-awesome-reveal';
import { keyframes } from '@emotion/react';
import { fetchHome } from '../../fetchers/home';
import {
  getLangCountry,
  getRealLangCountry,
  SUPPORTED_LANG
} from '../../helpers/languages';
import { LANG_STATIC_PATHS } from '../../helpers/staticPaths';
import { fetchAlternateUrls } from '../../fetchers/commons';
import { GetStaticPropsContext, NextPage } from 'next';
import { SUPPORTED_COUNTRY } from '../../helpers/countries';
import { AlternateLangs } from '../../models/app';
import { IHome, IHomeProduct } from '../../models';
import Image from 'next/image';
import HomeBanner from '../../components/HomeBanner';
import { useInView } from 'react-intersection-observer';
import { fetchHomeProducts } from '../../fetchers/products';
import { getToken } from '../../helpers/auth';
import { useRouter } from 'next/router';
// import NewsletterForm from '../../components/NewsletterForm';
import DiscountBanner from '../../components/DiscountBanner';
import MyCarouselGender from '../../components/Carousels/MyCarouselGender';
// import NewsletterForm from '../../components/NewsletterForm';

interface IHomePageProps {
  alternates: AlternateLangs[];
  dataHome: IHome;
}

const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(16px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const revealProps = {
  triggerOnce: true,
  fraction: 0,
  delay: 50,
  duration: 450,
  keyframes: customAnimation
};

const Home: NextPage<IHomePageProps> = ({ alternates, dataHome }) => {
  const token = getToken();
  const router = useRouter();
  const { lang, country } = getLangCountry(router.query);

  const [productsRef, productsInView] = useInView();
  const [products, setProducts] = useState<IHomeProduct[]>([]);
  const [productsLoading, setProductsLoading] = useState(false);

  const {
    header,
    'slider-categories': categories,
    'separator-image': separatorImage,
    // 'slider-products': products,
    'slider-quotations': reviews,
    banner,
    'banner-brands': bannerBrands
  } = dataHome || {};

  const fetchCarouselProducts = async () => {
    setProductsLoading(true);

    const products = await fetchHomeProducts({ lang, country, token });

    setProducts(products);
    setProductsLoading(false);
  };

  useEffect(() => {
    if (products.length === 0 && productsInView) {
      fetchCarouselProducts();
    }
  }, [productsInView]);

  return (
    <>
      <MyHead alternates={alternates} />
      <div>
        <Menu />
        <MycarouselHeader header={header} />

        <div ref={productsRef}>
          <Reveal {...revealProps}>
            <div className="px-4 py-16 bg-gray-100 sm:px-12">
              <MycarouselProducts
                loading={productsLoading}
                products={products}
              />
            </div>
          </Reveal>
        </div>

        <div>
          <Reveal {...revealProps}>
            <DiscountBanner banner={banner} />
          </Reveal>
        </div>

        <div className="px-4 py-16 bg-gray-100 sm:py-120 sm:px-12">
          <Reveal {...revealProps}>
            <MycarouselCategories categories={categories} />
          </Reveal>
        </div>

        <Footer />
      </div>
    </>
  );
};

export async function getStaticProps(context: GetStaticPropsContext) {
  const { params } = context;
  const { lang, country } = getLangCountry(params);

  const real = getRealLangCountry(params);
  if (
    !SUPPORTED_LANG.includes(real.lang) ||
    !SUPPORTED_COUNTRY.includes(real.country)
  ) {
    return { notFound: true };
  }

  const [dataHome = {}, alternates] = await Promise.all([
    fetchHome({ lang, country }),
    fetchAlternateUrls({
      url: '/',
      lang,
      country
    })
  ]);

  return {
    props: {
      alternates,
      dataHome
    },
    revalidate: 86400
  };
}

export async function getStaticPaths() {
  return {
    paths: LANG_STATIC_PATHS,
    fallback: 'blocking'
  };
}

export default Home;
