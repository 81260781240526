import React from 'react';
import Carousel from 'react-multi-carousel';
import { IHomeCategory } from '../../models';
import PrimaryButtonSmall from '../PrimaryButtonSmall';
import localizedRoute from '../../utils/localizedRoute';
import { useTranslation } from 'react-i18next';

const MycarouselCategories = ({
  categories = []
}: {
  categories: IHomeCategory[] | undefined;
}) => {
  const { t } = useTranslation();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      partialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 20
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
      partialVisibilityGutter: 20
    }
  };

  return (
    <>
      <div className="grid grid-cols-4 gap-4">
        <div className="hidden md:block"></div>
        <div className="col-span-4 md:col-span-2">
          <div className="text-center mb-10">
            <h2
              className="text-4xl md:text-40 leading-tight font-sans mb-2 text-black"
              dangerouslySetInnerHTML={{ __html: t('SECOND_CAROUSEL_TITLE') }}
            />
            <p
              className="text-md font-sans mb-6 opacity-60"
              dangerouslySetInnerHTML={{
                __html: t('SECOND_CAROUSEL_DESCRIPTION')
              }}
            />
          </div>
        </div>
        <div className="hidden md:block"></div>
      </div>

      <Carousel
        ssr
        partialVisible
        responsive={responsive}
        swipeable={true}
        draggable={true}
        showDots={false}
        containerClass="carousel-container sport-carousel"
        infinite={true}
        arrows={true}
      >
        {categories?.map(category => {
          return (
            <div
              className="relative px-2 h-400 mr-2"
              key={category.image}
              style={{
                background: `linear-gradient(to bottom, rgba(0, 20, 26, 0.1), rgba(0, 20, 26, 0.25)), url(" ${category.image} ")`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
              }}
            >
              <div className="absolute bottom-6 left-6">
                <p className="text-4xl text-white mb-2 tracking-wider font-sans font-bold">
                  {category.title}
                </p>
                <p>
                  <span className="mr-2">
                    <PrimaryButtonSmall
                      text={category.btn_text}
                      href={localizedRoute(category.btn_link)}
                    />
                  </span>
                </p>
              </div>
            </div>
          );
        })}
      </Carousel>
    </>
  );
};

export default MycarouselCategories;
