import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import { IHomeHeader } from '../../models';
import localizedRoute from '../../utils/localizedRoute';
import LinkTo from '../LinkTo';
import { filter } from 'lodash';

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<
    { width: number | undefined; height: number | undefined } | undefined
  >({
    width: undefined,
    height: undefined
  });

  useEffect(() => {
    // only execute all the code below in client side
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

const MycarouselHeader = ({ header }: { header: IHomeHeader }) => {
  const items = filter([header], Boolean);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 0 },
      items: 1
    }
  };

  const [isMobile, setIsMobile] = useState(false);

  const size = useWindowSize();
  useEffect(() => {
    if (size && size.width) {
      setIsMobile(size.width < 720);
    }
  }, [size]);
  return (
    <Carousel
      ssr
      partialVisible
      responsive={responsive}
      swipeable={items.length > 1}
      draggable={items.length > 1}
      containerClass="carousel-container"
      autoPlay={items.length > 1}
      autoPlaySpeed={5000}
      infinite={items.length > 1}
      arrows={false}
    >
      {items.map(item => {
        const imageUrl = isMobile
          ? item.image_mobile
            ? item.image_mobile
            : item.image
          : item.image;
        return (
          <div key={item.btn_link1}>
            <div
              className="text-white px-8 sm:px-12 lg:px-64 items-center"
              style={{
                background: `linear-gradient(to bottom, rgba(0, 20, 26, 0.1), rgba(0, 20, 26, 0.25)), url(${imageUrl})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: '50% 0%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '70vh'
              }}
            >
              <p className="uppercase mb-4 tracking-widest text-center">
                {item.text}
              </p>
              <h1 className="font-sans text-5xl md:text-5xl lg:text-7xl uppercase mb-6 text-center">
                {item.title}
              </h1>
              <div className="flex">
                <LinkTo
                  href={localizedRoute(item.btn_link1)}
                  className="bg-primary text-white font-sans font-medium px-4 py-6 rounded-md uppercase cursor-pointer w-70 sm:w-56 inline-block text-center"
                >
                  {item.btn_text1}
                </LinkTo>
                {item.btn_link2.length > 0 ? (
                  <LinkTo
                    href={localizedRoute(item.btn_link2)}
                    className="ml-2 bg-primary text-white font-sans font-medium px-4 py-6 rounded-md uppercase cursor-pointer w-70 sm:w-56 inline-block text-center"
                  >
                    {item.btn_text2}
                  </LinkTo>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </Carousel>
  );
};

export default MycarouselHeader;
